import React from 'react';
import { NavLink } from "react-router-dom";
import style from './navbar.module.scss'
import sprite from '../../assets/images/sprite.svg'
import classnames from 'classnames';

class Navigation extends React.Component {
  render() {
    return (
      <nav>
        <NavLink exact  to={'/'} className={style.link} activeClassName={style.selected}>
          <span>Home</span>
        </NavLink>
        <NavLink to={'/bio'} className={style.link} activeClassName={style.selected}>
          <span>Bio</span>
        </NavLink>
        <NavLink to={'/tour'} className={style.link} activeClassName={style.selected}>
          <span>Live</span>
        </NavLink>
        <NavLink to={'/reviews'} className={style.link} activeClassName={style.selected}>
          <span>Reviews</span>
        </NavLink>
      </nav>
    );
  }
}
class Social extends React.Component {
  render() {
    return (
      <div className={style.social}>
        <ul>
          <li>
            <a href="https://www.facebook.com/paolo.spaccamonti" rel="noopener noreferrer" target="_blank">
              <svg className={classnames('icon', style.facebook)}>
                <use xlinkHref={sprite+'#facebook'}></use>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/paolo_spaccamonti/" rel="noopener noreferrer" target="_blank">
              <svg className={classnames('icon', style.instagram)}>
                <use xlinkHref={sprite+'#instagram'}></use>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCV-uYvmLlNF_7YHQBXxwwvQ" rel="noopener noreferrer" target="_blank">
              <svg className={classnames('icon', style.youtube)}>
                <use xlinkHref={sprite+'#youtube'}></use>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://open.spotify.com/artist/6TYB590IguqPwF1mBdAZW5" rel="noopener noreferrer" target="_blank">
              <svg className={classnames('icon', style.spotify)}>
                <use xlinkHref={sprite+'#spotify'}></use>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://paolospaccamonti.bandcamp.com/" rel="noopener noreferrer" target="_blank">
              <svg className={classnames('icon', style.bandcamp)}>
                <use xlinkHref={sprite+'#bandcamp'}></use>
              </svg>
            </a>
          </li>
          <li>
            <a href="mailto:p.spacca@gmail.com" rel="noopener noreferrer">
              <svg className={classnames('icon', style.mail)}>
                <use xlinkHref={sprite+'#mail'}></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

class Navbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isMenuMobileOpened: false
    };

    this.openMobileMenu = this.openMobileMenu.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isMenuMobileOpened === true) {
      this.setState({
        isMenuMobileOpened: false
      });
    }
  }

  openMobileMenu(event) {
    event.preventDefault();
    this.setState(prevState => ({
      isMenuMobileOpened: !prevState.isMenuMobileOpened
    }));
  }

  render() {

    return (
      <div className={style.navbar}>
        <div className={style.wrapper}>
          <span className={style.logo}>Paolo Spaccamonti</span>
          <div className={style.navigation}>
            <Navigation />
            <Social />
          </div>
          <div className={classnames(style.mobileNavigation, {[style.opened] : this.state.isMenuMobileOpened})}>
              <svg onClick={(event) => this.openMobileMenu(event)} className={classnames('icon', style.close)}>
                <use xlinkHref={sprite+'#close'}></use>
              </svg>
            <Navigation />
            <Social />
          </div>
          <svg onClick={(event) => this.openMobileMenu(event)} className={classnames('icon', style.hamburger)}>
            <use xlinkHref={sprite+'#hamburger'}></use>
          </svg>
        </div>
      </div>
    );

  }

}

export default Navbar;
