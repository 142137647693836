import React from 'react';
import style from './venue.module.scss';
import moment from 'moment';
import 'moment/locale/it';
import classnames from 'classnames';

class Venue extends React.Component {

  render() {

    console.log(this.props.venue)

    const localLocale = moment(new Date(this.props.venue.title || this.props.venue.summary)); 

    const venueDate = localLocale.format('LL');

    const isArchive = localLocale.isBefore();

    return (
      <div className={classnames(style.venue, {[style.past] : isArchive})}>
        <h3>{venueDate}</h3>
        <div className={style.content} dangerouslySetInnerHTML={{__html: this.props.venue.body}}></div>
      </div>
    );
  }

}

export default Venue;
