import React from 'react';
import style from './reviews.module.scss';
import DataList from '../../components/datalist/datalist';

import cln from '../../assets/images/albums-covers/cln.jpg';
import torturatori from '../../assets/images/albums-covers/torturatori.jpg';
import cormorani from '../../assets/images/albums-covers/cormorani.jpg';
import rumors from '../../assets/images/albums-covers/rumors.jpg';
import burnout from '../../assets/images/albums-covers/burnout.jpg';
import frammenti from '../../assets/images/albums-covers/frammenti.jpg';
import kennel from '../../assets/images/albums-covers/kennel.jpg';
import buonenotizie from '../../assets/images/albums-covers/buone-notizie.jpg';
import undici from '../../assets/images/albums-covers/undici-pezzi-facili.jpg';
import volumequattro from '../../assets/images/albums-covers/volume-quattro.jpg';
import neltorbido from '../../assets/images/albums-covers/nel-torbido.jpg';

class Reviews extends React.Component {

  render() {

    const options= [
      {
        label: 'NEL TORBIDO',
        value: 'nel-torbido'
      },
      {
        label: 'VOLUME QUATTRO',
        value: 'volume-quattro'
      },
      {
        label: 'CLN',
        value: 'cln'
      },
      {
        label: 'TORTURATORI',
        value: 'torturatori'
      },
      {
        label: 'I CORMORANI OST',
        value: 'cormorani'
      },
      {
        label: 'RUMORS',
        value: 'rumors'
      },
      {
        label: 'BURNOUT',
        value: 'burnout'
      },
      {
        label: 'FRAMMENTI',
        value: 'frammenti'
      },
      {
        label: 'SPACCAMOMBU',
        value: 'kennel'
      },
      {
        label: 'BUONE NOTIZIE',
        value: 'buone-notizie'
      },
      {
        label: 'UNDICI PEZZI FACILI',
        value: 'undici-pezzi-facili'
      }
    ];

    const albumsData = {
      cln: {
        cover: cln,
        infos: '<p>Paolo Spaccamonti & Jochen Arbeit<br /><strong>CLN</strong></p>'
              +'<p>All music by Jochen Arbeit (Einstürzende Neubauten) and Paolo Spaccamonti</p>'
              +'<p>Jochen Arbeit: electric guitar, fx<br />Paolo Spaccamonti: electric guitar, fx</p>' 
              +'<p>Recorded by Paul Beauchamp at OF.F. Studio, Torino<br />Mixed by Gup Alcaro at Superbudda, Torino<br />Mastered by James Plotkin<br />Produced by Gup Alcaro and Paolo Spaccamonti</p>'
              +'<p>Cover art "Cold Bathing" by Laura Fortin<br />Layout by Luigi Pugliano</p>'
              +'<p>Released by Boring Machines and Escape From Today<br />white vinyl 180 gr + digital download code</p>'
              +'released March 9, 2018 '
      },
      torturatori: {
        cover: torturatori,
        infos: '<p>Paolo Spaccamonti & Paul Beauchamp<br /><strong>TORTURATORI</strong></p>'
              +'<p>All music by Paul Beauchamp & Paolo Spaccamonti</p>'
              +'<p>Paul Beauchamp: electric harmonium, musical saw, synth<br />Paolo Spaccamonti: acoustic guitar, baritone guitar, fx</p>' 
              +'<p>Recorded, mixed and produced by Gianmaria Aprile at Argo Laboratorium, Gorla Maggiore (VA)<br />Mastered by James Plotkin<br />Released by Escape From Today Records, Frattonove<br />Distributed by Audioglobe<br />LTD, red vinyl, digital download code included<br />Artwork by Stefano Steuso Manzi</p>'
              +'<p>released April 3, 2017</p>'
            },
      cormorani: {
        cover: cormorani,
        infos: '<p>Paolo Spaccamonti & Ramon Moro<br /><strong>I CORMORANI OST</strong></p>'
              +'<p>clear vinyl 180 gr. + download code</p>'
              +'<p>All music by Paolo Spaccamonti and Ramon Moro</p>' 
              +'<p>Paolo Spaccamonti: electric guitar, mandocaster, fx<br />Ramon Moro: trumpet, flugelhorn, fx<br />Gup Alcaro: live electronics<br />Recorded, mixed and produced by Gup Alcaro at Superbudda<br />Mastered by Giuseppe Ielasi<br />Released by Dunque, Superbudda I Distributed by Audioglobe<br />Artwork by Bellissimo and the Beast</p>'
              +'<p>Original soundtrack from I Cormorani<br />a film by Fabio Bobbio I produced by Strani Film</p>'
              +'<p>released December 1, 2016</p>'
      },
      rumors: {
        cover: rumors,
        infos: '<p>Paolo Spaccamonti<br /><strong>RUMORS</strong></p>'
              +'<p>All music by Paolo Spaccamonti</p>'
              +'<p>Dario Bruna: drums on \'dead set\' and \'giorni contati\', piano on \'rumors\'<br />Bruno Dorella: drums on \'croci/fiamme\'<br />Julia Kent: cello on \'io ti aspetto\'<br />Stefano Fano Roman: beat, rhodes and moog on \'gordo\'<br />Paolo Spaccamonti: guitars, bass, synth, farfisa, beat, fx<br />Davide Tomat: beat  on \'fango\'</p>' 
              +'<p>Produced by Gup Alcaro and Paolo Spaccamonti<br />Recorded and mixed by Gup Alcaro at Superbudda, Torino, Italy<br />Mastered by Teho Teardo at Basement, Roma, Italy</p>'
              +'<p>Released by Santeria, Escape From Today<br />Distributed by Audioglobe</p>'
              +'<p>released April 20, 2015</p>'
      },
      burnout: {
        cover: burnout,
        infos: '<p>Paolo Spaccamonti & Daniele Brusaschetto<br /><strong>BURNOUT (august sessions)</strong></p>'
              +'<p>All music by Paolo Spaccamonti and Daniele Brusaschetto<br />Produced by Daniele Brusaschetto and Paolo Spaccamonti<br />Recorded, mixed and Master by Daniele Brusaschetto at O.F.F., Torino, Italy</p>'
              +'<p>Brigadisco, Old Bicycle Records, Bosco Rec<br />MC, 120 copies (With free download code)<br />Artwork by Rocco Lombardi<br />SOLD OUT</p>'
              +'<p>released December 14, 2014</p>'
      },
      frammenti: {
        cover: frammenti,
        infos: '<p>Paolo Spaccamonti & Stefano Pilia<br /><strong>Split ep</strong></p>'
              +'<p>Escape from Today, Brigadisco<br />Artwork by: Rocco Lombardi<br />Screen Printed Cover by: Sericraft , 2013<br />Lp screen printed cover<br />70 numbered copies black vinyl / 230 numbered copies clear vinyl<br />(LTD numbered copies )</p>'
              +'<p>SOLD OUT</p>'
              +'<p>Produced by Ezra and Paolo Spaccamonti<br />Recorded, mixed and mastered by Ezra @ No.Mad Studio, Torino, Italy, 2012</p>'
              +'<p>released April 2, 2013</p>'
      },
      kennel: {
        cover: kennel,
        infos: '<p>Spaccamombu (Spaccamonti+Mombu)<br /><strong>IN THE KENNEL VOL. 2</strong></p>'
              +'<p>LP 180gr - Goat Man Records, Noja / Goodfellas</p>'
              +'<p>Paolo Spaccamonti: guitar<br />Luca T. Mai: baritone sax, electronics<br />Antonio Zitarelli: drums and percussion</p>'
              +'<p>All songs written by Luca T. Mai except \'Assufais\' by Antonio Zitarelli<br />All arrangements by Spaccamombu</p>'
              +'<p>Engineered, mixed and mastered by Massimiliano Moccia at Blue Records Studio (Mondovì), march 11-12 2012</p>'
              +'<p>released November 1, 2012</p>'
      },
      ['buone-notizie']: {
        cover: buonenotizie,
        infos: '<p>Paolo Spaccamonti<br /><strong>BUONE NOTIZIE</strong></p>'
              +'<p>Recorded, mix and master by Marco Milanesio, O.F.F. Studio, Torino, Italy<br />Produced by Marco Milanesio and Paolo Spaccamonti</p>'
              +'<p>All music by Paolo Spaccamonti<br />except 6,10,11 (Spaccamonti/Piccirillo)</p>'
              +'<p>Featuring: Julia Kent, Fabrizio Modonese Palumbo, Daniele Brusaschetto, Ezra, Davide Compagnoni, Marco Piccirillo, Dario Bruna, Ramon Moro.</p>'
              +'<p>Bosco Rec (cd) / Escape From Today (lp)<br />Distributed by Audioglobe.</p>'
              +'<p>released May 5, 2011</p>'
      },
      ['undici-pezzi-facili']: {
        cover: undici,
        infos: '<p>Paolo Spaccamonti<br /><strong>UNDICI PEZZI FACILI</strong></p>'
              +'<p>Recorded and mixed by Daniele Brusaschetto<br />except 7- 8 -11 by Ezra @ No.Mad Studio.<br />Master by Marco Milanesio @ O.F.F. Studio</p>'
              +'<p>All music by Paolo Spaccamonti<br />except 7, 8,11 (Spaccamonti/Piccirillo)</p>'
              +'<p>featuring: Daniele Brusaschetto, Francesco Cocola, Ezra, Marco Piccirillo, Simone Sanna, Paola Secci, Beatrice Zanin.</p>'
              +'<p>Bosco Rec (cd)<br />Distributed by Audioglobe. </p>'
              +'<p>released May 5, 2009</p>'
      },
      ['volume-quattro']: {
        cover: volumequattro,
        infos: '<p>Paolo Spaccamonti<br /><strong>VOLUME QUATTRO</strong></p>'
              +'<p>All music by Paolo Spaccamonti</p>'
              +'<p>Paolo Spaccamonti: guitars<br />Davide Tomat: beat on "ablazioni"</p>'
              +'<p>Recorded and produced by Gup Alcaro at Superbudda, Torino<br />except "Paul dance" by Maurizio Borgna<br />Mixed by Maurizio Borgna</p><br />Mastered by Patrick Higgins'
              +'<p>Cover art by Laura Fortin<br />Layout design by Luigi Pugliano</p>'
              +'<p>Released by Escape From Today and Dunque<br />silver vinyl  + digital download code</p>'
              +'<p>released September 20, 2019</p>'
      },
      ['nel-torbido']: {
        cover: neltorbido,
        infos: '<p>Paolo Spaccamonti<br /><strong>NEL TORBIDO</strong></p>'
              +'<p>All music by Paolo Spaccamonti</p>'
              +'<p>Dario Bruna: drums on 2<br>'
              +'Enrico Gabrielli: alto sax, flute, bass clarinet on 2, 3, 6<br>'
              +'Julia Kent: cello on 6<br>'
              +'Paolo Spaccamonti: guitars</p>'
              +'<p>Recording by Claudio Tortorici<br>'
              +'Mix by Nicola Sannino<br>'
              +'Processing, mastered and produced by Gup Alcaro</p>'
              +'<p>Photographs by Francesca Togni<br>'
              +'Layout by Andrea Endi Sassano</p>'
              +'<p>Released by Liza<br>'
              +'Distributed by Audioglobe</p>'
              +'<p>released April 19, 2024</p>'
      }
    }

    return (
      <div className={style.reviews}>
         <DataList 
          blog="paolospaccamonti-reviews"
          client={this.props.client}
          options={options}
          albumsData={albumsData}
        />
      </div>
    );

  }

}

export default Reviews;
