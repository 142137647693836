import React from 'react';
import style from './post.module.scss';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/it';

class Post extends React.Component {

  render() {

    const localLocale = moment(this.props.post.date, 'YYYY-MM-DD');

    //TO DO per inglese localLocale.locale('en'); 

    const postDate = localLocale.format('LL');

    const currentDate= moment(this.props.post.date, 'YYYY-MM-DD').format('YYYY-MM-DD');

    const archiveDate = moment('2019-05-22', 'YYYY-MM-DD').format('YYYY-MM-DD');

    const isArchive = moment(currentDate).isSameOrBefore(archiveDate, 'day');

    return (
      <div key={this.props.post.id} className={classnames(style.post, {[style.photoPost] : this.props.post.type === 'photo'})}>
        {this.props.post.type === 'photo' && (
          <div className={style.images}>
            {this.props.post.photos.map((image, index) => (
              <div
                key={index}
                className={style.image}
              >
                {!!this.props.post.link_url && (
                  <a href={this.props.post.link_url} rel="noopener noreferrer" target="_blank"></a>
                )}
                <img src={image.original_size.url} alt=""/>                 
              </div>
            ))}
          </div>
        )}
        {this.props.post.type === 'video' && (
            <div className={style.video}>
              {this.props.post.player.map((v, index) => {
                if(v.width === 500) {
                  return (
                    <div key={index} dangerouslySetInnerHTML={{__html: v.embed_code}}></div>
                  );
                }else{
                  return '';
                }
              })}
            </div>
          )}
        <div className={style.content}>
          {!isArchive ? 
            <span className={style.data}>{postDate}</span> :
            <span className={style.data}>Archivio</span>
          }
          {this.props.post.title && (
            <div className={style.title}>
              <h2>{this.props.post.title}</h2>
            </div>
          )}
          {this.props.post.type === 'photo' || this.props.post.type === 'video' ?
            <div className={style.text} dangerouslySetInnerHTML={{__html: this.props.post.caption}}></div> :
            <div className={style.text} dangerouslySetInnerHTML={{__html: this.props.post.body}}></div>  
          }
        </div>
      </div>
    );

  }

}

export default Post;
